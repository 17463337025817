import { Box, Fab, Typography, Drawer, Button, makeStyles } from '@material-ui/core';
import { CloseRounded, MenuRounded } from '@material-ui/icons';
import React, { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { StateContext } from '../context';
import { AccessRole, ID, PermissionName } from '../core';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 1350,
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    minWidth: 250,
    width: '40vw',
    maxWidth: 400,
  },
  greeting: {
    fontSize: '1.4rem',
    marginLeft: theme.spacing(2),
  },
}));

interface Props {}

export const Menu: FC<Props> = () => {
  const classes = useStyles();
  const { user, logOut } = useContext(StateContext);
  const [showMenu, setShowMenu] = useState(false);
  const [canManageGroupLeaders] = useState<boolean>(
    !!user?.permissions?.items
      ?.map((i) => i?.permission.permissionName)
      .find((pName) => pName === PermissionName.ManageGroupLeaders)
  );
  const [canViewActivityHistory] = useState<boolean>(
    !!user?.permissions?.items
      ?.map((i) => i?.permission.permissionName)
      .find((pName) => pName === PermissionName.ViewActivityLog)
  );

  return (
    <>
      <Box className={classes.fab}>
        <Fab
          id={ID.Menu}
          color="primary"
          size="small"
          onClick={() => setShowMenu((showMenu) => !showMenu)}
          aria-label={showMenu ? 'close' : 'menu'}
        >
          {showMenu ? <CloseRounded /> : <MenuRounded />}
        </Fab>
        <Typography id={ID.Greeting} align="center" style={{ fontSize: '1.4rem', marginLeft: '16px' }}>
          <span role="img" aria-label="hello">
            👋
          </span>{' '}
          {user?.firstName}
        </Typography>
      </Box>
      <Drawer open={showMenu} onClose={() => setShowMenu(false)}>
        <Box className={classes.drawer}>
          <Box display="flex" flexDirection="column" padding="80px 16px">
            {[AccessRole.Admin, AccessRole.Registrar].includes(user?.accessRole as AccessRole) && (
              <Button
                color="primary"
                component={Link}
                to={'/registrations'}
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Registrations
              </Button>
            )}
            {[AccessRole.Admin, AccessRole.Registrar, AccessRole.GroupLeader].includes(
              user?.accessRole as AccessRole
            ) && (
              <Button
                id={ID.Menu_Groups}
                color="primary"
                component={Link}
                to="/groups"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Groups
              </Button>
            )}
            {user?.accessRole === AccessRole.Admin && (
              <Button
                color="primary"
                component={Link}
                to="/discounts"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Discount Packages
              </Button>
            )}
            {user?.accessRole === AccessRole.Admin && (
              <Button
                color="primary"
                component={Link}
                to="/roles"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Roles
              </Button>
            )}
            {(user?.accessRole === AccessRole.Admin || canManageGroupLeaders) && (
              <Button
                id={ID.Menu_Users}
                color="primary"
                component={Link}
                to="/users"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Users
              </Button>
            )}
            {user?.accessRole === AccessRole.Admin && (
              <Button
                color="primary"
                component={Link}
                to="/downloads"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Downloads
              </Button>
            )}
            {(user?.accessRole === AccessRole.Admin || canViewActivityHistory) && (
              <Button
                color="primary"
                component={Link}
                to="/activity"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Activity Log
              </Button>
            )}
            {user?.accessRole === AccessRole.Admin && (
              <Button
                color="primary"
                component={Link}
                to="/switches"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Master Switches
              </Button>
            )}
            {user?.accessRole === AccessRole.Admin && (
              <Button
                color="primary"
                component={Link}
                to="/individuals"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Individuals
              </Button>
            )}
            {user?.accessRole === AccessRole.Admin && (
              <Button color="default" href="https://trello.com/b/TgVqD3ko" target="_blank" style={{ margin: '0.5rem' }}>
                Todo List
              </Button>
            )}
            {user?.accessRole === AccessRole.Admin && (
              <Button
                color="primary"
                component={Link}
                to="/errors"
                onClick={() => setShowMenu(false)}
                style={{ margin: '0.5rem' }}
              >
                Error Log
              </Button>
            )}
            <Button id={ID.Menu_LogOut} color="secondary" onClick={() => logOut()} style={{ margin: '0.5rem' }}>
              Log out
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
