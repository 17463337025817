import { API } from 'aws-amplify';
import * as d3 from 'd3';
import xml2js from 'xml2js';
import { DpoXmlData, DpoIndividual, DpoCsvData } from './donor-perfect.types';
import { mergeDpoApiData, mergeDpoCsvData, transformDpoApiData } from './donor-perfect.utils';

/**
 * Donor Perfect Service
 */

export abstract class DonorPerfectService {
  static apiGetIndividuals = (): Promise<DpoIndividual[]> =>
    API.get('restapi', '/individuals', {})
      .then((res) => res.data)
      .then((xml) => xml2js.parseStringPromise(xml) as Promise<DpoXmlData>)
      .then(transformDpoApiData)
      .then(mergeDpoApiData);

  static csvUploadIndividuals = (csvFile: File): Promise<any[]> =>
    parse(csvFile)
      .then(mergeDpoCsvData)
      .then((mergedData) => {
        localStorage.setItem('csvFileName2024', csvFile.name);
        localStorage.setItem('csvFileData2024', JSON.stringify(mergedData));
        return mergedData;
      });
}

/**
 * Helpers
 */

function parse(csvFile: File): Promise<DpoCsvData[]> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const text = (e.target?.result as string).split('\r\n\r\n')[1];
          const csvData = d3.csvParse(text);
          resolve(csvData as unknown as DpoCsvData[]);
        } catch (error) {
          reject(JSON.stringify(error));
        }
      };
      reader.readAsText(csvFile);
    } catch (error) {
      reject(JSON.stringify(error));
    }
  });
}
