/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://izx588hu07.execute-api.us-east-1.amazonaws.com/prodfour",
            "region": "us-east-1"
        },
        {
            "name": "restapi",
            "endpoint": "https://4h0ajipg11.execute-api.us-east-1.amazonaws.com/prodfour",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://72bqddumevacho54gyk4assaqi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:4de34b6c-6b26-427f-b680-be3f2bd65a53",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Dw9B8tkGX",
    "aws_user_pools_web_client_id": "3i5d61thro7lrvran1v4t3k65n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_content_delivery_bucket": "babtl-20240101215828-hostingbucket-prodfour",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1dxhwm4awhc8m.cloudfront.net"
};


export default awsmobile;
