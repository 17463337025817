import { Box, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StateContext } from '../context';
import { DonorPerfectService, ID, DpoCsvData, useMaxTableBodyHeight, RegistrationService } from '../core';
import { Form } from './Form';
import MaterialTable, { Column } from 'material-table';
import { RegistrationForm } from './RegistrationForm';
import { withModal } from './withModal';

interface DataFileUploadForm {
  File: any;
}

interface Props {}

export const Individuals: FC<Props> = withModal((props) => {
  const { withLoading, registrations, updateRegistrations } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<DataFileUploadForm>();
  const [data, setData] = useState<DpoCsvData[]>(
    localStorage.getItem('csvFileData2024') ? JSON.parse(localStorage.getItem('csvFileData2024') as string) : []
  );
  const [countNew, setCountNew] = useState<number>(0);

  useEffect(() => {
    const count = data.reduce((p, c) => {
      if (!registrations?.find((r) => r.donorPerfectId === c.EntryId)) {
        return p + 1;
      }
      return p;
    }, 0);
    setCountNew(count);
  }, [data, registrations]);

  const autoRegister = withLoading<[DpoCsvData]>(async (item) => {
    try {
      const data = {
        donorPerfectId: item.EntryId,
        firstName: item.first_name.trim(),
        lastName: item.last_name.trim(),
        email: item.email.trim(),
        phone: item.home_phone.replace(/\D/g, ''),
        fridaySpeakers: item.sub_solicit_code.includes('FRI_SPEAK_ONLY'),
        fridayBreakfast: item.sub_solicit_code.includes('FRI_BREAKFAST'),
        fridayLunch: item.sub_solicit_code.includes('FRI_LUNCH'),
        fridayDinner: item.sub_solicit_code.includes('FRI_DINNER'),
        saturdaySpeakers: item.sub_solicit_code.includes('SAT_SPEAK_ONLY'),
        saturdayBreakfast: item.sub_solicit_code.includes('SAT_BREAKFAST'),
        saturdayLunch: item.sub_solicit_code.includes('SAT_LUNCH'),
        saturdayDinner: item.sub_solicit_code.includes('SAT_DINNER'),
        notes: 'Individual (DPO)',
      };

      // add speakers pass if individual registered for a meal
      if (!data.fridaySpeakers && (data.fridayBreakfast || data.fridayLunch || data.fridayDinner)) {
        data.fridaySpeakers = true;
        data.notes += ' + added Friday speakers';
      }
      if (!data.saturdaySpeakers && (data.saturdayBreakfast || data.saturdayLunch || data.saturdayDinner)) {
        data.saturdaySpeakers = true;
        data.notes += ' + added Saturday speakers';
      }

      await RegistrationService.create(data);
      updateRegistrations();
      enqueueSnackbar(`Successfully created registration for ${item.first_name} ${item.last_name}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Failed to create registration for ${item.first_name} ${item.last_name}`, { variant: 'error' });
    }
  });

  const customSort = useCallback(
    (a: DpoCsvData, b: DpoCsvData) => {
      const existingA = registrations?.find((r) => r.donorPerfectId === a.EntryId);
      if (existingA) return 1;
      const existingB = registrations?.find((r) => r.donorPerfectId === b.EntryId);
      if (existingB) return -1;
      return 0;
    },
    [registrations]
  );

  const columns: Column<DpoCsvData>[] = [
    {
      title: 'Status',
      customSort,
      render: (item) => {
        const existing = registrations?.find((r) => r.donorPerfectId === item.EntryId);
        return existing ? (
          <Button
            color="primary"
            onClick={() =>
              props.openModal(
                <RegistrationForm existingData={registrations?.find((r) => r.donorPerfectId === item.EntryId)} />,
                600
              )
            }
          >
            Edit
          </Button>
        ) : (
          <Button color="secondary" onClick={() => autoRegister(item)}>
            Register
          </Button>
        );
      },
    },
    { title: 'Date', field: 'EntryDate' },
    { title: 'Id', field: 'EntryId' },
    { title: 'First', field: 'first_name' },
    { title: 'Last', field: 'last_name' },
    { title: 'Phone', field: 'home_phone' },
    { title: 'Email', field: 'email' },
    { title: 'campaign', field: 'campaign' },
    { title: 'gl_code', field: 'gl_code' },
    { title: 'sub_solicit_codes', field: 'sub_solicit_code' },
  ];

  const onSubmit = form.handleSubmit(
    withLoading<[DataFileUploadForm]>(async (data) => {
      try {
        if (!data.File[0]) {
          throw new Error('Please choose a file.');
        }
        await DonorPerfectService.csvUploadIndividuals(data.File[0])
          .then((data) => setData(data))
          .catch((error) => {
            throw error;
          });
      } catch (error: any) {
        enqueueSnackbar(error?.message || `Failed to read CSV file. Error: ${JSON.stringify(error)}`, {
          variant: 'error',
        });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
      <Box width="100%">
        <Form form={form} onSubmit={onSubmit}>
          <input type="file" accept=".csv" name="File" ref={form.register()} />
          <Button
            id={ID.Form_Individuals_File_Upload}
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: '0.5rem', marginRight: '1.5rem' }}
            size="small"
          >
            Upload
          </Button>
        </Form>
        <MaterialTable
          title={
            <Box display="flex" flexWrap="nowrap" alignItems="center" marginRight="12px">
              <Typography variant="h6" style={{ paddingRight: '16px' }}>
                Individuals
              </Typography>
              <Typography variant="h6" color="secondary" style={{ paddingRight: '16px' }}>
                {countNew} pending registrations
              </Typography>
              {localStorage.getItem('csvFileName2024') && (
                <Typography variant="body1" color="primary">
                  {localStorage.getItem('csvFileName2024')}
                </Typography>
              )}
            </Box>
          }
          columns={columns}
          data={data}
          options={{
            paging: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            minBodyHeight: useMaxTableBodyHeight(),
            maxBodyHeight: useMaxTableBodyHeight(),
            padding: 'dense',
          }}
        />
      </Box>
    </Box>
  );
});
