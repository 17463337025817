import React, { FC, CSSProperties, useState, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff, CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@material-ui/icons';

import { useSnackbar } from 'notistack';

import logo from '../assets/babtl-logo.jpg';
import { StateContext } from '../context';
import { AuthChallenge, AuthService, AWSCognitoUser, ID, PASSWORD_POLICIES } from '../core';

interface DataLogin {
  Email: string;
  Password: string;
}

interface DataForgotPasswordReset extends DataLogin {
  VerificationCode: string;
}

export const Login: FC = () => {
  const { updateUser, withLoading } = useContext(StateContext);
  const { register, handleSubmit } = useForm<DataLogin>({ reValidateMode: 'onSubmit' });
  const [showPassword, setShowPassword] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [cognitoUser, setCognitoUser] = useState<AWSCognitoUser | null>(null);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [forgotPasswordReset, setForgotPasswordReset] = useState<boolean>(false);

  const onClickForgotPassword = useCallback(() => {
    setPassword('');
    if (forgotPassword) setForgotPassword(false);
    else if (forgotPasswordReset) setForgotPasswordReset(false);
    else setForgotPassword(true);
  }, [forgotPasswordReset, forgotPassword]);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmitSignIn = handleSubmit(
    withLoading<[DataLogin]>(async (data) => {
      try {
        const cognitoUser = await AuthService.signIn(data.Email, data.Password);
        if (cognitoUser.challengeName === AuthChallenge.NEW_PASSWORD_REQUIRED) {
          setNewPasswordRequired(true);
          setPassword('');
          setShowPassword(false);
          setCognitoUser(cognitoUser);
          const firstName = cognitoUser.challengeParam.userAttributes.name.split(' ')[0];
          enqueueSnackbar(`👋 ${firstName}! Please create a new password.`, { variant: 'info' });
        } else {
          await updateUser();
        }
      } catch (error: any) {
        enqueueSnackbar(error.message || 'Login failed.', { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  const onSubmitConfirmNewPassword = handleSubmit(
    withLoading<[DataLogin]>(async (data) => {
      try {
        if (PASSWORD_POLICIES.every((policy) => policy.regex.test(password))) {
          await AuthService.completeNewPassword(cognitoUser!, data.Password);
          await updateUser();
        } else {
          enqueueSnackbar('Password does not meet requirements.', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Unable to create new password. Try again.', { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  const onSubmitForgotPassword = handleSubmit(
    withLoading<[Omit<DataLogin, 'Password'>]>(async (data) => {
      try {
        await AuthService.forgotPassword(data.Email);
        enqueueSnackbar(`A password reset email was sent to ${data.Email}`, { variant: 'success' });
        setForgotPasswordReset(true);
        setForgotPassword(false);
      } catch (error) {
        enqueueSnackbar(error.message || 'Unable to send password reset email.', { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  const onSubmitForgotPasswordReset = handleSubmit(
    withLoading<[DataForgotPasswordReset]>(async (data) => {
      try {
        if (PASSWORD_POLICIES.every((policy) => policy.regex.test(password))) {
          await AuthService.forgotPasswordSubmit(data.Email, data.VerificationCode, data.Password);
          enqueueSnackbar(`Password reset successful!`, { variant: 'success' });
          await AuthService.signIn(data.Email, data.Password);
          await updateUser();
        } else {
          enqueueSnackbar('Password does not meet requirements.', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(error.message || 'Password reset failed', { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  return (
    <>
      <Box style={styles.container}>
        <img src={logo} alt="logo" style={styles.logo} />
        <Box height="10px" />
        <Box style={styles.actionTray}>
          <Typography color="primary">March 8-9, 2024</Typography>
          <Typography color="secondary" style={styles.button}>
            //
          </Typography>
          <Typography color="primary">Independence, OH</Typography>
        </Box>
        <Box height="30px" />
        <form
          onSubmit={
            forgotPassword
              ? onSubmitForgotPassword
              : forgotPasswordReset
              ? onSubmitForgotPasswordReset
              : newPasswordRequired
              ? onSubmitConfirmNewPassword
              : onSubmitSignIn
          }
        >
          <Box display="flex" flexDirection="column" width={styles.logo.width} maxWidth={350} minWidth={260}>
            <TextField
              id={ID.Login_Email}
              name="Email"
              label="Email"
              variant="filled"
              inputRef={register({ required: 'Email required' })}
              InputProps={{ readOnly: forgotPasswordReset || newPasswordRequired }}
              fullWidth
            />
            {forgotPasswordReset && (
              <>
                <Box height="10px" />
                <TextField
                  name="VerificationCode"
                  label="Verification Code"
                  variant="outlined"
                  inputRef={register({ required: 'Verification code required' })}
                  fullWidth
                />
              </>
            )}
            {!forgotPassword && (
              <>
                <Box height="10px" />
                <TextField
                  id={ID.Login_Password}
                  name="Password"
                  label={forgotPasswordReset || newPasswordRequired ? 'New Password' : 'Password'}
                  type={showPassword ? 'text' : 'password'}
                  variant={forgotPasswordReset || newPasswordRequired ? 'outlined' : 'filled'}
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  inputRef={register({ required: 'Password required' })}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
            <Box height="10px" />
            <Button id={ID.Login_Submit} type="submit" color="primary" variant="contained" style={styles.button}>
              {forgotPassword
                ? 'Send Password Reset Email'
                : forgotPasswordReset || newPasswordRequired
                ? 'Create New Password'
                : 'Log in'}
            </Button>
            {!newPasswordRequired && (
              <Button color="secondary" size="small" style={styles.button} onClick={onClickForgotPassword}>
                {forgotPassword || forgotPasswordReset ? 'Cancel' : 'Forgot Password?'}
              </Button>
            )}
          </Box>
        </form>
        {(forgotPasswordReset || newPasswordRequired) && (
          <Box display="flex" flexDirection="column" marginTop="0.5rem">
            {PASSWORD_POLICIES.map((policy) => (
              <Box display="flex">
                {policy.regex.test(password) ? (
                  <CheckBoxRounded style={{ color: 'green' }} />
                ) : (
                  <CheckBoxOutlineBlankRounded color="action" />
                )}
                <Typography style={{ marginLeft: '0.5rem' }}>{policy.label}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 'calc(0.5 * (300px + 40vw))',
    minWidth: '280px',
    maxWidth: '450px',
    marginTop: '50px',
  },
  actionTray: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: '0.5rem',
  },
};
