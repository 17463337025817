import jsPDF from 'jspdf';
import { PRICING, REGISTRATION_ARRAY } from './constants';
import { Totals } from './discount.service';
import { ConferenceDay, PermissionName } from './enums';
import { Group, AppPermission, Role, User } from './graphql.types';

export function formatCurrency(amount: number): string {
  return amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatPhoneNumber(raw: string): string {
  const cleaned = raw.replace(/\D/g, '');
  if (!cleaned) return '';
  if (cleaned.length < 4) return `(${cleaned}`;
  const area = cleaned.substring(0, 3);
  const first3 = cleaned.substring(3, 6);
  if (cleaned.length < 7) return `(${area}) ${first3}`;
  const last4 = cleaned.substring(6, 10);
  return `(${area}) ${first3}-${last4}`;
}

export function downloadTableList(day: ConferenceDay, users: User[], groups: Group[]): void {
  function createHeader(name: string, width: number, align: 'left' | 'center') {
    return { name, width, align };
  }
  const headers = [
    createHeader('First', 35, 'left'),
    createHeader('Last', 35, 'left'),
    createHeader('Group', 110, 'left'),
  ];
  let data: any[];
  const dataByName: any[] = [];
  const dataByGroup: any[] = [];

  switch (day) {
    case ConferenceDay.Thursday:
      data = users
        .filter((user) => !!user.thursdayReception)
        .map((user) => {
          const groups = user.groups?.items?.map((item) => item?.group.groupName || '').sort() || [];
          return {
            First: user.firstName,
            Last: user.lastName,
            Group: groups.join('\n') || '~',
            groups,
          };
        });

      break;
    case ConferenceDay.Friday:
      headers.push(
        createHeader('Breakfast', 25, 'center'),
        createHeader('Lunch', 25, 'center'),
        createHeader('Dinner', 25, 'center')
      );
      data = users
        .filter((user) => !!user.fridaySpeakers)
        .map((user) => {
          const groups = user.groups?.items?.map((item) => item?.group.groupName || '').sort() || [];
          return {
            First: user.firstName,
            Last: user.lastName,
            Group: groups.join('\n') || '~',
            Breakfast: user.fridayBreakfast ? 'Yes' : 'No',
            Lunch: user.fridayLunch ? 'Yes' : 'No',
            Dinner: user.fridayDinner ? 'Yes' : 'No',
            groups,
          };
        });
      break;
    case ConferenceDay.Saturday:
      headers.push(
        createHeader('Breakfast', 25, 'center'),
        createHeader('Lunch', 25, 'center'),
        createHeader('Dinner', 25, 'center')
      );
      data = users
        .filter((user) => !!user.saturdaySpeakers)
        .map((user) => {
          const groups = user.groups?.items?.map((item) => item?.group.groupName || '').sort() || [];
          return {
            First: user.firstName,
            Last: user.lastName,
            Group: groups.join('\n') || '~',
            Breakfast: user.saturdayBreakfast ? 'Yes' : 'No',
            Lunch: user.saturdayLunch ? 'Yes' : 'No',
            Dinner: user.saturdayDinner ? 'Yes' : 'No',
            groups,
          };
        });
      break;
    default:
      data = [];
      break;
  }

  data.forEach((x) => dataByName.push({ ...x }));

  var doc = new jsPDF({ orientation: 'portrait' });

  doc.setTextColor('#d82622'); // red
  doc.setFont('helvetica', 'normal', 700);
  doc.setFontSize(20);
  doc.text(`${day} (by Last Name)`, 108, 20, { align: 'center' });

  doc.setTextColor(0); // black
  doc.table(10, 30, dataByName, headers as any, {
    fontSize: 9,
    padding: 2,
    margins: { top: 10, bottom: 10, right: 10 },
  });

  if (day !== ConferenceDay.Thursday) {
    const duplicates: any[] = [];
    data.forEach((x) => {
      if (x.groups.length > 1) {
        for (let i = 0; i < x.groups.length; i++) {
          duplicates.push({ ...x, Group: x.groups[i] });
        }
      }
    });
    data
      .filter((x) => x.groups.length <= 1)
      .concat(duplicates)
      .sort((a, b) => (a.Last < b.Last ? -1 : a.Last > b.Last ? 1 : 0))
      .sort((a, b) => (a.Group < b.Group ? -1 : a.Group > b.Group ? 1 : 0))
      .forEach((x) => dataByGroup.push({ ...x }));

    doc.addPage();

    doc.setTextColor('#d82622'); // red
    doc.setFont('helvetica', 'normal', 700);
    doc.setFontSize(20);
    doc.text(`${day} (by Group)`, 108, 20, { align: 'center' });

    doc.setTextColor(0); // black
    doc.table(10, 30, dataByGroup, headers as any, {
      fontSize: 9,
      padding: 2,
      margins: { top: 10, bottom: 10, right: 10 },
    });
  }

  doc.save(`${day}TableLists2024.pdf`);
}

export function downloadCsv(users: User[]): void {
  const columns: string[] = [
    'First',
    'Last',
    'Group',
    'Role',
    ...REGISTRATION_ARRAY.flatMap(([day, items]) => items.map((item) => `${day} ${item}`)),
    'Notes',
    'Email',
    'Phone',
  ];

  const rows: string[][] = users?.map((user) => [
    user.firstName || '',
    user.lastName || '',
    user.groups?.items?.map((item) => item?.group.groupName || '').join('\n') || '',
    user.role?.roleName || '',
    ...REGISTRATION_ARRAY.flatMap(([day, items]) =>
      items.map((item) => (user[`${day.toLowerCase()}${item}` as keyof User] ? 'y' : ''))
    ),
    user.notes || '',
    user.email || '',
    formatPhoneNumber(user.phone || ''),
  ]);

  const data: string[][] = [columns, ...rows].map((row) => row.map((cell) => `"${cell.replace(/"/g, '""')}"`));

  const csvContent = 'data:text/csv;charset=utf-8,' + data.map((e) => e.join(',')).join('\n');

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'babtl_registrations_2024.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function sortUsersAlpha(a: User, b: User): number {
  const aLast = a.lastName.toLowerCase();
  const bLast = b.lastName.toLowerCase();
  if (aLast < bLast) return -1;
  if (aLast > bLast) return 1;
  const aFirst = a.firstName.toLowerCase();
  const bFirst = b.firstName.toLowerCase();
  if (aFirst < bFirst) return -1;
  if (aFirst > bFirst) return 1;
  return 0;
}

export function sortGroupsAlpha(a: Group, b: Group): number {
  return a.groupName.localeCompare(b.groupName);
}

export function sortRolesAlpha(a: Role, b: Role): number {
  return a.roleName.localeCompare(b.roleName);
}

export function sortPermissionsAlpha(a: AppPermission, b: AppPermission): number {
  return a.permissionName.localeCompare(b.permissionName);
}

export function mapUserPrimaryGroup(user: User | null | undefined): Group | undefined {
  return user?.groups?.items?.map((item) => item?.group).shift();
}

export function isPermitted(
  user: User | null | undefined,
  permissions: AppPermission[] | null | undefined,
  permissionName: PermissionName
): boolean {
  return (
    !!permissions?.find((p) => p.permissionName === permissionName)?.accessEnabled?.includes(user?.accessRole!) ||
    !!user?.permissions?.items?.map((i) => i?.permission.permissionName).find((pName) => pName === permissionName)
  );
}

export const getRandomInt = (length: number): string => {
  let output = '';
  while (length-- > 0) {
    output += Math.floor(Math.random() * 10).toFixed(0);
  }
  return output;
};

export const calculateIndividualTotalDue = (user: User, totals: Totals): number => {
  let individualRetail = 0;
  if (user.fridaySpeakers) individualRetail += PRICING.Speakers;
  if (user.fridayBreakfast) individualRetail += PRICING.Breakfast;
  if (user.fridayLunch) individualRetail += PRICING.Lunch;
  if (user.fridayDinner) individualRetail += PRICING.Dinner;
  if (user.saturdaySpeakers) individualRetail += PRICING.Speakers;
  if (user.saturdayBreakfast) individualRetail += PRICING.Breakfast;
  if (user.saturdayLunch) individualRetail += PRICING.Lunch;
  if (user.saturdayDinner) individualRetail += PRICING.Dinner;
  return (individualRetail * totals.actual) / totals.retail;
};
